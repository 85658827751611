import React, { useState, lazy, useEffect } from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import { ethers } from 'ethers'
import pcsRouter from './web3/pcsRouter'
import mATHEN from './web3/mainAthen'
import Web3Modal from "web3modal";
//import fetchInject from "fetchInject";
//import mATHEN from './web3/mainATHEN'
import WalletConnectProvider from "@walletconnect/web3-provider";

// import {
//   Button,
//   Modal, ModalHeader, ModalBody, ModalFooter
// } from '@windmill/react-ui'
import AccessibleNavigationAnnouncer from './components/AccessibleNavigationAnnouncer';
const Layout = lazy(() => import('./containers/Layout'))
const provider = new ethers.providers.JsonRpcProvider("https://bsc-dataseed1.defibit.io/")

const apiKey = '7ZZ4WKZZIB8B71XYFV8Z1I1WD7B44GZCUJ'

let timer

const ATHENContractAddress = mATHEN.address;
const ATHENDecimals = 18;
const ATHENAbi = mATHEN.abi;

const ATHENContract = new ethers.Contract(ATHENContractAddress, ATHENAbi, provider)
const pcsRouterContract = new ethers.Contract(pcsRouter.address, pcsRouter.abi, provider)

const bnb = {
  address: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
  decimals: 18,
}
const busd = {
  address: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
  decimals: 18,
}


async function getAmountsOut(quoteAmount, path) {
  return await pcsRouterContract.functions['getAmountsOut'](
    quoteAmount,
    path,
    { gasLimit: 1000000000000 }
  )
}

async function getATHENPrice() {
  let functionResponse;
  try{
  functionResponse = await getAmountsOut(`${1 * Math.pow(10, ATHENDecimals)}`, [ATHENContractAddress, bnb.address, busd.address])
  }catch(err){
    functionResponse = {amounts:[0,0,0]}
  }
  const priceInUsd = ethers.utils.formatEther(functionResponse.amounts[2].toString())
  console.log('ATHEN', priceInUsd, functionResponse.amounts[2].toString())
  return priceInUsd
}

async function getBnbPrice() {
  const functionResponse = await getAmountsOut(`${1 * Math.pow(10, bnb.decimals)}`, [bnb.address, busd.address])
  const priceInUsd = Number(functionResponse.amounts[1].toString()) / Math.pow(10, bnb.decimals)
  console.log('bnb', priceInUsd)
  return priceInUsd
}

async function getATHENVolume() {
  const res = await fetch('https://api.coingecko.com/api/v3/simple/price?ids=tiki-token&vs_currencies=usd&include_market_cap=false&include_24hr_vol=true&include_24hr_change=false&include_last_updated_at=false')
  const resolved = await res.json()
  const volume = resolved['tiki-token'].usd_24h_vol
  return 1
}



function App() {
  const [contract, setContract] = useState(null);
  const [wallet, setWallet] = useState(null)
  const [totalPaid, setTotalPaid] = useState(0)
  const [bnbHoldings, setBnbHoldings] = useState(0)
  const [bnbPrice, setBnbPrice] = useState(0)
  const [ATHENPrice, setATHENPrice] = useState(0)
  const [ATHENVolume, setATHENVolume] = useState(0)
  const [dividendToken, setDividendToken] = useState(null); 
  const [label, setLabel] = useState();
  const [defaultOption, setDefaultOption] = useState();
  const [providerAddress, setProviderAddress] = useState(null);

  const [highestBuyers, setHighestBuyers] = useState([])

  const [holdings, setHoldings] = useState(0)
  const [paid, setPaid] = useState(0)
  const [lastPaid, setLastPaid] = useState(0)
  const [nextPayoutProgress, setNextPayoutProgress] = useState(0)
  const [nextPayoutValue, setNextPayoutValue] = useState(0)

  const [refreshAddressData, setRefreshAddressData] = useState(true)
  const [refreshTimeData, setRefreshTimeData] = useState(true)

  const [isModalOpen, setIsModalOpen] = useState(true)
  function setMLabel(x){
    setLabel(x);
  }
/*****************/
  async function getMetamaskWallet() {
    let metamask;
    const providerOptions = {
      walletconnect: {
        package: WalletConnectProvider, // required
        options: {
          rpc: {
            56: 'https://bsc-dataseed.binance.org/'
          },
          network: 'binance',
          chainId: 56,
          infuraId: 'f6adf3f23ca149c8a2e5e0e4e32b617d',
        }
      }
  }
    const web3Modal = new Web3Modal({
      theme: 'dark', // optional
      providerOptions, // required
      cacheProvider: true, // optional
    });
    try {
      console.log('tried connect')
      let provider = await web3Modal.connect();
     //metamask = new ethers.providers.Web3Provider(window.ethereum)
     metamask = new ethers.providers.Web3Provider(provider)
     console.log(metamask)
      let { chainId } = await metamask.getNetwork()
      if(chainId != 56){
        throw 'Wrong Chain!'
      }
    } catch (e) {
      return null;
    }
    // Prompt user for account connections
   // await metamask.send("eth_requestAccounts", []);
    return metamask.getSigner();
    // metamask.getSigner().getAddress
  }

  //~~~~
  function connectWallet(){
    setAddress('')
    getMetamaskWallet().then((w) => {
      if (w === null){
        setDividendToken(null);
        return
      }
      w.getAddress().then(async (address) => {
        let HPContract = new ethers.Contract(mATHEN.address, mATHEN.abi, w)
        setAddress(address);
        setProviderAddress(address);
        try{
        }catch(err){
          console.log('could not set label', err)
        }
        setContract(HPContract);
        /*
        contract.balanceOf(address).then((balance) => {
          setContract(contract);
          setBalance(parseInt(balance._hex, 16) / 1e18);
        });
        contract.withdrawableDividendOf(address).then((withdrawable) => {
          setWithdrawable(parseInt(withdrawable._hex, 16) / 1e18);
        });
        contract.withdrawnDividendOf(address).then((withdrawn) => {
          setWithdrawn(parseInt(withdrawn._hex, 16) / 1e18);
        });*/
      });
    });
  }
  function openModal() {
    setIsModalOpen(true)
  }

  function closeModal() {
    setIsModalOpen(false)
  }

  const [address, setAddress] = useState(
    localStorage.getItem('address') || ''
  )  
  
  const [providerObj, setProviderObj] = useState(null);

  useEffect(() => {
    getATHENPrice().then(setATHENPrice)
    getBnbPrice().then(setBnbPrice)
    getATHENVolume().then(setATHENVolume)
  }, [])

  useEffect(() => {

    if (ethers.utils.isAddress(address)) {
      if (localStorage.getItem('address') !== address) localStorage.setItem('address', address)
      callContract(address)
    }
  }, [address, refreshAddressData])

  useEffect(() => {
    ATHENContract.getTotalDividendsDistributed().then(total => {
      setTotalPaid((total/1e18).toFixed(0))
      setTimeout(function(){ setRefreshTimeData(!refreshTimeData) }, 5000);
    })
  }, [refreshTimeData])

  // getAccountDividendsInfo returns []:
  // address Address
  // int256 index,
  // int256 iterationsUntilProcessed,
  // uint256 withdrawableDividends,
  // uint256 totalDividends,
  // uint256 lastClaimTime,
  // uint256 nextClaimTime,
  // uint256 secondsUntilAutoClaimAvailable

  const callContract = () => {
    ATHENContract.getNumberOfDividendTokenHolders().then(holders => {
      ATHENContract.balanceOf(address).then(balance => {
        console.log(balance.toString())
        setHoldings((balance / (10**ATHENDecimals)).toFixed(0))
          ATHENContract.getAccountDividendsInfo(address).then(result => {
            console.log('paidAmount', result)
            provider.getBalance(address).then(balance => {
              setBnbHoldings((balance/1e18).toFixed(4))
              setPaid( parseInt(result[4]._hex, 16) - parseInt(result[3]._hex, 16) )
              setLastPaid(parseInt(result[5]._hex, 16)*1000)
              setNextPayoutProgress((100-((parseInt(result[2]._hex, 16)/parseInt(holders._hex, 16))*100)).toFixed(0))
              setNextPayoutValue( (parseInt(result[3]._hex, 16)/1e18).toFixed(4) )
              window.clearTimeout(timer);
              timer = window.setTimeout(function(){ setRefreshAddressData(!refreshAddressData) }, 9000);
            })
          })
        })
      })
    }

  return (
    <>
      {/* <Modal isOpen={isModalOpen} onClose={closeModal}>
        <ModalHeader className="text-center text-4xl -mt-4">Instant Payout Feature</ModalHeader>
        <ModalBody className="text-center text-2xl">
          If you <a href="https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x9b76D1B12Ff738c113200EB043350022EBf12Ff0" target="_blank" rel="noopener noreferrer"><span className="semibold text-green-400">BUY more ATHEN (click here)</span></a>, your payout is <span className="text-red-400">INSTANTLY</span> sent to you!
        </ModalBody>
        <ModalFooter className="-mt-2">
          <div className="hidden sm:block w-full text-center mb-4">
            <Button className="w-3/4" onClick={closeModal}>
              Got It
            </Button>
          </div>
        </ModalFooter>
      </Modal> */}

      <Router>
        <AccessibleNavigationAnnouncer />
        <Switch>
          <Route path="/" render={(props) => (<Layout {...props} providerAddress={providerAddress} setLabel={setLabel} defaultOption={defaultOption} label={label} dividendToken={dividendToken} contract={contract} setContract={setContract} providerObj={providerObj} setProviderObj={setProviderObj} getMetamaskWallet={getMetamaskWallet} connectWallet={connectWallet} ATHENPrice={ATHENPrice} address={address} setAddress={setAddress} holdings={holdings} setHoldings={setHoldings} paid={paid} setPaid={setPaid} lastPaid={lastPaid} setLastPaid={setLastPaid} nextPayoutProgress={nextPayoutProgress} setNextPayoutProgress={setNextPayoutProgress} totalPaid={totalPaid} nextPayoutValue={nextPayoutValue} setNextPayoutValue={setNextPayoutValue} bnbHoldings={bnbHoldings} bnbPrice={bnbPrice} highestBuyers={highestBuyers} wallet={wallet} ATHENVolume={ATHENVolume} setATHENVolume={setATHENVolume} setProviderAddress={setProviderAddress} />)} />
        </Switch>
      </Router>
    </>
  )

}

export default App
